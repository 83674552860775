import React, { useState, useEffect, useContext, useCallback } from 'react';

// Material UI components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// Serendipity components
import {
    GlucoseGraph,
    BGCard,
    DataSourceSelector,
    ServerStatusHeader
} from 'components/Serendipity';

import { useInterval } from 'hooks/useIntervalHook';

// Auth Context
import { AuthContext } from 'lib/Auth';
import firebase from '../../services/firebase';

import { getNightscoutEntries } from 'lib/nightscout';
import { DexcomClient } from 'lib/dexcom';
import { AbbottClient } from 'lib/abbott';

let database = firebase.database();

export default function Dashboard(props) {
    const { userPreferences, updateUserContext } = useContext(AuthContext);
    const { nightscout, abbott, uid } = userPreferences;

    const abbottClient = new AbbottClient(abbott);
    const dexcomClient = new DexcomClient(nightscout);

    const { nsRefreshInterval, BG_TARGET_TOP, BG_TARGET_BOTTOM } = nightscout;

    const [preferences, setPreferences] = useState(userPreferences);
    // Set up initial state for the dashboard.
    const [entries, setEntries] = useState([]);

    const DEFAULT_REFRESH_INTERVAL = 5 * 60 * 1000;

    const [fetchingEntries, setFetchingEntries] = useState(true);

    const DEFAULT_ENTRY = {
        x: new Date(),
        y: 0,
        raw: {
            Value: 0,
            Trend: 'FLAT'
        }
    };

    const [latestEntry, setLatestEntry] = useState(DEFAULT_ENTRY);

    const [priorEntry, setPriorEntry] = useState(DEFAULT_ENTRY);

    const [refreshInterval, setNSRefreshInterval] = useState(
        DEFAULT_REFRESH_INTERVAL
    );

    const [lastFetchTime, setLastFetchTime] = useState('Updating...');

    const [chartDataSource, setChartDataSource] = useState('NS');

    const getEntries = useCallback(async () => {
        let entries = [];

        // Clear current state when fetching from a new data source.
        setFetchingEntries(true);
        setLatestEntry(DEFAULT_ENTRY);
        setPriorEntry(DEFAULT_ENTRY);

        // Each data source returns formatted entries for our dashboard.
        // Fix how we set the latest and prior entries.  This is sloppy.

        switch (chartDataSource) {
            case 'NS':
                const nightscoutOpts = {
                    nsApiUrl: nightscout.base_url,
                    queryStartTime: new Date().getTime() - 24 * 60 * 60 * 1000,
                    count: 100
                };
                const nsEntries = await getNightscoutEntries(nightscoutOpts);

                if (nsEntries?.formattedEntries?.length > 0) {
                    entries = nsEntries.formattedEntries;
                    const numEntries = entries.length;
                    setLatestEntry(entries[numEntries - 1]);
                    setPriorEntry(entries[numEntries - 2]);
                }

                break;
            case 'DX':
                entries = await dexcomClient.getEntries();
                setLatestEntry(dexcomClient.latestEntry);
                setPriorEntry(dexcomClient.priorEntry);
                break;
            case 'AB':
                entries = await abbottClient.getEntries();
                setLatestEntry(abbottClient.latestEntry);
                setPriorEntry(abbottClient.priorEntry);
                break;
            default:
                break;
        }

        if (entries.length > 0) {
            setEntries(entries);
            setLastFetchTime(
                new Date(
                    new Date().getTime() + nsRefreshInterval
                ).toLocaleTimeString()
            );
            setFetchingEntries(false);
        } else {
            setFetchingEntries(false);
        }
    }, [chartDataSource]);

    useInterval(async () => {
        getEntries();
    }, refreshInterval);

    useEffect(() => {
        getEntries();
    }, [getEntries]);

    useEffect(() => {
        const updatePreferences = async () => {
            let userPrefRef = database.ref('preferences/' + uid);
            userPrefRef.on('value', (snapshot) => {
                const userPreferences = snapshot.val();

                setPreferences(userPreferences);
            });
        };

        updatePreferences();
    }, [uid]);

    const handleNSContextUpdate = (key, val) => {
        const updatedNSContext = nightscout;
        updatedNSContext[key] = val;
        updateUserContext('nightscout', updatedNSContext);
    };

    const handleNSIntervalSelect = (event) => {
        const updatedInterval = event.target.value;
        handleNSContextUpdate('nsRefreshInterval', updatedInterval);
        setNSRefreshInterval(updatedInterval);
    };

    const handleDataSourceSelect = (event) => {
        const { value } = event.target;
        // Clear entries whenever a data source is selected.
        setEntries([]);
        setChartDataSource(value);
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <DataSourceSelector
                                handleDataSourceSelect={handleDataSourceSelect}
                                chartDataSource={chartDataSource}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <ServerStatusHeader userPreferences={preferences} />
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4} lg={3}>
                            <BGCard
                                loading={fetchingEntries}
                                data={entries}
                                latestEntry={latestEntry}
                                priorEntry={priorEntry}
                                updateTime={lastFetchTime}
                                targetHigh={BG_TARGET_TOP}
                                targetLow={BG_TARGET_BOTTOM}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={8}>
                            <GlucoseGraph
                                data={entries}
                                loading={fetchingEntries}
                                targetHigh={BG_TARGET_TOP}
                                targetLow={BG_TARGET_BOTTOM}
                                handleDataSourceSelect={handleDataSourceSelect}
                                chartDataSource={chartDataSource}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
}
