import React, { useEffect, useState } from 'react';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { makeStyles } from '@material-ui/core/styles';
import {
    dailyReportStyleBase,
    dailyEntriesStyle
} from '../../assets/jss/DailyReportStyle';

const useStyles = makeStyles(dailyReportStyleBase);

export default function DailyEntriesChart(props) {
    const classes = useStyles();

    const { bgTargetBottom, bgTargetTop } = props;
    const { lowThreshold, highThreshold, inRange } = dailyEntriesStyle;
    const DEFAULT_LINE_CHART_OPTIONS = {
        title: {
            text: ''
        },
        xAxis: { type: 'datetime' },
        yAxis: { title: { text: 'mg/dL' } },
        series: [
            {
                type: 'line',
                name: 'Glucose Readings',
                data: [],
                zones: [
                    { value: bgTargetBottom, color: lowThreshold },
                    { value: bgTargetTop, color: inRange },
                    { color: highThreshold }
                ]
            }
        ],
        credits: {
            enabled: false
        },
        legend: { enabled: false }
    };

    const [chartOptions, setChartOptions] = useState(
        DEFAULT_LINE_CHART_OPTIONS
    );

    useEffect(() => {
        // Timezone is needed to handle local time
        const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const { seriesData, bgTargetBottom, bgTargetTop } = props;

        // Set the chart options on component load.
        const updatedChartOptions = {
            ...DEFAULT_LINE_CHART_OPTIONS,
            time: {
                timezone: timeZoneString,
                useUTC: true
            }
        };

        updatedChartOptions.series[0].data = seriesData;
        updatedChartOptions.series[0].zones = [
            { value: bgTargetBottom, color: lowThreshold },
            { value: bgTargetTop, color: inRange },
            { color: highThreshold }
        ];

        setChartOptions(updatedChartOptions);
    }, [props]);

    return (
        <div>
            <Card>
                <CardHeader className={classes.cardHeader}>
                    <div className={classes.cardTitle}>
                        <span className={classes.cardTitleText}>
                            Daily Entries
                        </span>
                        <div
                            style={{
                                float: 'right',
                                marginLeft: '15px',
                                marginTop: '-5px'
                            }}
                        ></div>
                        <div style={{ float: 'right', marginLeft: '5px' }}>
                            <span className={classes.legendDotRed} />
                            <span>Out of Range</span>
                        </div>
                        <div style={{ float: 'right', marginLeft: '5px' }}>
                            <span className={classes.legendDotGreen} />
                            <span>
                                Target Range: {props.bgTargetBottom} and{' '}
                                {props.bgTargetTop}
                            </span>
                        </div>
                    </div>
                </CardHeader>
                <CardBody className={classes.cardBody}>
                    <div className={classes.dailyMinMaxTableContainer}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            containerProps={{ style: { height: '100%' } }}
                        />
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
