//https://gist.github.com/StephenBlackWasAlreadyTaken/adb0525344bedade1e25
//https://github.com/gagebenne/pydexcom

//https://github.com/gagebenne/pydexcom#why-not-use-the-official-dexcom-developer-api

// Use official API for reporting
// User Share API for realtime.
// https://developer.dexcom.com/support/faq

//https://github.com/gagebenne/pydexcom/blob/main/pydexcom/const.py

import dexcomConfig from './dexcom-config';
const {
    authEndPoint,
    loginEndpoint,
    latestGlucoseEndpoint,
    applicationId
} = dexcomConfig;

export const writeGlucoseEntries = async (req) => {};

export class DexcomClient {
    constructor(opts) {
        const DEXCOM_SERVERS = [
            { US: 'https://share2.dexcom.com' },
            { OUS: 'https://shareous1.dexcom.com' }
        ];

        this.latestEntry = {
            x: new Date(),
            y: 0,
            raw: {
                Value: 0,
                Trend: 'FLAT'
            }
        };
        this.priorEntry = {
            sgv: 0,
            trend: '',
            timestamp: ''
        };
        this.entries = [];
        this.rawData = [];
        this.TREND_MAP = {
            FLAT: 'FLAT',
            UP: 'UP',
            DOUBLEUP: 'DOUBLEUP',
            DOWN: 'DOWN',
            SINGLEDOWN: 'SINGLEDOWN',
            FORTYFIVEUP: 'FORTYFIVEUP',
            FORTYFIVEDOWN: 'FORTYFIVEDOWN',
            SINGLEUP: 'SINGLEUP'
        };
        this.sessionId = '';
        this.accountId = '';
        this.DEXCOM_USER = opts.BRIDGE_USER_NAME || '';
        this.DEXCOM_PASSWORD = opts.BRIDGE_PASSWORD || '';
        this.DEXCOM_REGION = opts.BRIDGE_SERVER || 'US';
        this.DEXCOM_API_HOST =
            opts.BRIDGE_SERVER === 'US'
                ? DEXCOM_SERVERS[0].US
                : DEXCOM_SERVERS[1].OUS;
    }

    getLoginSession = async () => {
        if (!this.accountId) {
            console.log('No auth ID provided.  Unable to get a session ID.');
            return;
        }
        const authHeaders = {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        };

        const authBody = {
            accountId: this.accountId,
            password: this.DEXCOM_PASSWORD,
            applicationId: applicationId
        };

        try {
            const response = await fetch(this.DEXCOM_API_HOST + loginEndpoint, {
                method: 'POST',
                headers: authHeaders,
                body: JSON.stringify(authBody)
            });

            if (response.status !== 200) {
                throw new Error(
                    'Error getting a Dexcom session ID: ',
                    response.statusText
                );
            }
            const authResponse = await response.json();

            return authResponse;
        } catch (e) {
            console.log(
                'Unable to get an authentication response from Dexcom!',
                e
            );
        }
    };

    getAccountId = async () => {
        const authHeaders = {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        };

        const authBody = {
            accountName: this.DEXCOM_USER,
            password: this.DEXCOM_PASSWORD,
            applicationId: applicationId
        };

        try {
            const response = await fetch(this.DEXCOM_API_HOST + authEndPoint, {
                method: 'POST',
                headers: authHeaders,
                body: JSON.stringify(authBody)
            });

            if (response.status !== 200) {
                throw new Error(
                    'Error logging into Dexcom!  Check your credentials. ',
                    response.statusText
                );
            }
            const authResponse = await response.json();
            this.accountId = authResponse;
            return this.accountId;
        } catch (e) {
            console.log('Error logging into Dexcom!  Check your credentials. ');
            return;
        }
    };

    formatEntries = async () => {
        let formattedData = [];

        if (this.rawData?.length > 0) {
            formattedData = this.rawData?.map((entry) => {
                const { WT, Value } = entry;
                let timestamp = new Date(
                    parseInt(WT.substring(5, WT.length - 1))
                );

                let sgValue = parseInt(Value);

                return {
                    x: timestamp,
                    y: sgValue,
                    raw: entry
                };
            });
        }
        this.entries = formattedData;

        // Always set the latest entry when fetching new data
        this.latestEntry = this.entries[0];

        // Always set the prior entry when fetching new data
        this.priorEntry = this.entries[1];

        return this.entries;
    };

    getDexcomData = async () => {
        // Get session ID from the auth call.
        this.accountId = await this.getAccountId();

        if (!this.accountId) {
            throw new Error('Unable to get an auth ID from Dexcom!');
        }
        //Get session ID from the login call.
        this.sessionId = await this.getLoginSession();

        if (!this.sessionId) {
            console.log(
                'No session ID provided.  Unable to get glucose entries.'
            );
            return;
        }
        const reqHeaders = {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        };

        const queryParams = new URLSearchParams({
            sessionID: this.sessionId,
            minutes: 1440,
            maxCount: 50
        });

        try {
            const entriesUrl = new URL(
                this.DEXCOM_API_HOST + latestGlucoseEndpoint
            );

            entriesUrl.search = queryParams;

            const response = await fetch(entriesUrl, {
                method: 'POST',
                headers: reqHeaders
            });

            if (response.status !== 200) {
                return [];
            }
            this.rawData = await response.json();
            await this.formatEntries();
        } catch (e) {
            console.log('An error occurred interacting with Dexcom!', e);
        }

        return this.rawData;
    };

    async updateEntries() {
        return await this.getDexcomData();
    }

    async getEntries() {
        if (this.entries.length === 0) {
            await this.getDexcomData();
            return this.entries;
        }
        return this.entries;
    }
}
